export const skills = [
    "JavaScript",
    "Node.JS",
    "React.JS",
    "Express.JS",
    "HTML",
    "CSS",
    "TailWindCSS",
    "Git",
    "Non-Relational DBMS",
    "Relational DBMS",
    "AWS (Lambda, S3, DynamoDB)",
    "Docker",
    "Redis",
    "REST API",
]